
/**
 * 不能有空格
 * @param {string} name 提示的表单名字
 */
export const notSpace = function (name) {
  return (rule, value, callback) => {
    if (/^\s+$/.test(value)) {
      callback(new Error(name + "不能为空格"));
    } else {
      callback();
    }
  };
};

/**
* 比较两个日期 2020-02-20
* @param start 起始日期
* @param end   结束日期
* @returns {boolean} true|false
*/
export const compareDate = function (start, end) {
  return new Date(end).getTime() > new Date(start).getTime();
};

/**
 * 开始日期校验，不能比结束时间晚
 * @param {Date} etime 结束时间
 */
export const validateStartTime = function (etime) {
  return (rule, value, callback) => {
    if (!value) return callback(new Error("起始日期不能为空！"));
    // 如果结束日期没选，不管
    if (!etime) {
      callback();
    } else {
      if (this.compareDate(value, etime)) {
        // 如果起始在结束之前
        callback();
      } else {
        callback(new Error("起始日期不能在结束日期之后，请重新选择"));
      }
    }
  };
};

/**
 * 结束时间不能比开始时间早
 * @param {Date} rtime 开始时间
 */
export const validateEndTime = function (rtime) {
  return (rule, value, callback) => {
    if (!value) return callback(new Error("结束日期不能为空！"));
    // 如果起始日期没选，不管
    if (!rtime) {
      callback();
    } else {
      if (this.compareDate(rtime, value)) {
        // 如果结束在起始之后
        callback();
      } else {
        callback(new Error("结束日期不能在起始日期之前，请重新选择"));
      }
    }
  };
};

/**
 * @description: 数值不能小于0
 * @param {rule} 校验的规则
 * @param {value} 校验的值
 * @param {callback} 校验后的回调
 */
export const notZero = function (rule, value, callback) {
  if (value > 0) {
    callback();
  } else {
    callback(new Error("数值不能小于0"));
  }
};


/**
 * @description: 验证手机号码
 * @param {rule} 校验的规则
 * @param {value} 校验的值
 * @param {callback} 校验后的回调
 */
export const phone = function (rule, value, callback) {
  if (!value) {
    callback()
    return
  }
  if (!/^[\d]{11}$/.test(value)) {
    callback(new Error("请输入正确的手机号"));
    return;
  };
  callback();
};

export const emailChecker = (rule, value, callback) => {
  if (!value) {
    // 必填校验由开发者自己再次添加一条 required: true 的规则
    return callback()
  }
  let re = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
  if (re.test(value)) {
    // 验证通过
    callback()
  } else {
    callback(new Error('请输入正确的电子邮箱'))
  }
}

/**
 * @description: 复杂的密码正则校验 大写字母+小写字母+数字+特殊字符
 * @param {*} min 最低位数
 * @param {*} max 最高位数
 * @author: mulingyuer
 */
export const complexPass = function (min = 6, max = 16) {
  return (rule, value, callback) => {
    const errorText = `密码为${min}-${max}位，至少含有一个大小写字母数字以及特殊字符!@-_=#*%+&^$`;
    //不能为空
    if (value === "") {
      callback(new Error(errorText));
    }
    //包含大写字母
    if (!/[A-Z]/.test(value)) {
      callback(new Error(errorText));
    }
    //包含小写字母
    if (!/[a-z]/.test(value)) {
      callback(new Error(errorText));
    }
    //包含数字
    if (!/\d/.test(value)) {
      callback(new Error(errorText));
    }
    //包含特殊字符
    if (!/[!@\-_=#\*%\+&\^\$]/.test(value)) {
      console.log(111)
      callback(new Error(errorText));
    }
    //位数
    if (!new RegExp(`.{${min},${max}}`).test(value)) {
      callback(new Error(errorText));
    }
    //通过
    callback();
  };
};

/**
 * @description: 密码校验：大写或小写字母+数字+特殊字符
 * @param {*} min 最低位数
 * @param {*} max 最高位数
 * @author: mulingyuer
 */
export const mediumPass = function (min = 6, max = 16) {
  return (rule, value, callback) => {
    const errorText = `密码为${min}-${max}位，必须包含数字和字母和至少一个特殊字符!@-_=#*%+&^$`;
    //不能为空
    if (value === "") {
      callback(new Error(errorText));
    }
    //包含大写或者小写字母
    if (!/[A-Za-z]/.test(value)) {
      callback(new Error(errorText));
    }
    //包含数字
    if (!/\d/.test(value)) {
      callback(new Error(errorText));
    }
    //包含特殊字符
    if (!/[!@\-_=#\*%\+&\^\$]/.test(value)) {
      callback(new Error(errorText));
    }
    //位数
    if (!new RegExp(`.{${min},${max}}`).test(value)) {
      callback(new Error(errorText));
    }
    //通过
    callback();
  }
}


/**
 * @description: 简单的密码正则校验
 * @param {rule} 校验的规则
 * @param {value} 校验的值
 * @param {callback} 校验后的回调
 */
export const simplePass = function (min = 6, max = 16) {
  return (rule, value, callback) => {
    const passReg = new RegExp(`^[a-zA-Z0-9!@\-_=#\*%\+&\^\$]{${min},${max}}$`);
    if (passReg.test(value)) {
      if (!/\d+/.test(value) || !/[a-zA-Z]/.test(value)) {
        callback(
          new Error(
            `密码为${min}-${max}位，必须包含数字和字母`
          )
        );
        return;
      }
    } else {
      callback(
        new Error(
          `密码为${min}-${max}位，必须包含数字和字母`
        )
      );
      return;
    }
    callback();
  }
}

/**
 * 校验图片字段是否为空，顺带加上错误样式
 * @param{Object} self              this
 * @param{Array | String} dataName  图片字段名
 * @param{Object} options           其他可配置项，如{formName = 'form', msg = '请选择图片'}
 * @returns {function(...[*]=)}
 */
export const checkImg = function (self, dataName, options = {}) {
  let _options = options || {}
  let formName = _options.formName || 'form';
  let msg = _options.msg || '请选择图片';
  return (rule, value, callback) => {
    if (!self) {
      throw new Error('请传入this')
    }
    if (!self[formName]) {
      throw new Error('表单不存在')
    }
    // 包含所有错误字段名的数组
    let _err = [];
    if (dataName instanceof Array) {
      dataName.forEach(item => {
        if (!self[formName][item]) {
          _err.push(item);
        }
      })
    } else {
      if (!self[formName][dataName]) {
        _err = [dataName]
      }
    }
    if (_err.length) {
      // 打算通过组件标签上的prop属性控制错误样式
      // _err.forEach(r => {
      //   document.querySelector('[prop=' + r + ']') &&
      //   document.querySelector('[prop=' + r + ']').classList.add('is-error')
      // })
      callback(new Error(msg))
    } else {
      // _err.forEach(r => {
      //   document.querySelector('[prop=' + r + ']') &&
      //   document.querySelector('[prop=' + r + ']').classList.remove('is-error')
      // })
      callback()
    }
  }
}


/**
 * @description: 校验是否合法链接
 * @param {String} message 报错提示
 * @Date: 2021-10-09 14:29:29
 * @Author: mulingyuer
 */
export const isURL = function (message = "请输入合法链接") {
  return function (rule, value, callback) {
    const reg = /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i;
    if (!reg.test(value)) {
      return callback(new Error(message))
    }
    return callback();
  }
}


export default {
  notSpace,
  compareDate,
  validateStartTime,
  validateEndTime,
  notZero,
  phone,
  complexPass,
  mediumPass,
  simplePass,
  checkImg,
  isURL,
  emailChecker
};

<template>
  <el-drawer
    :visible="visible"
    :with-header="false"
    :wrapper-closable="false"
    @close="close()"
    append-to-body
    close-on-press-escape
    size="760px"
  >
    <div @click="close()" class="close-absolute flex-center cursor-pointer">
      <i class="el-icon-close"></i>
    </div>
    <ai-chat />
  </el-drawer>
</template>

<script>
import { mapState } from 'vuex'
import AiChat from '../../ai/components/AIChat'

export default {
  name: 'ai-chat-dialog',
  components: {AiChat},
  props: {},
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    ...mapState('header', ['isOpenAIChat']),
  },
  watch: {
    isOpenAIChat: {
      handler(newValue) {
        this.visible = newValue
      },
      immediate: true
    },
  },
  methods: {
    /**
     * exposed
     */
    open() {
      this.$store.dispatch('header/openChat', {
        foo: 'bar',
      })
    },
    /**
     * exposed
     */
    close() {
      this.$store.dispatch('header/closeChat', {
        foo: 'bar',
      })
    },
  },
}
</script>

<style lang="scss">
.close-absolute {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 6px;
  z-index: 1;

  .el-icon-close {
    font-size: 22px;
  }
}
</style>

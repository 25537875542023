import api from '@/base/utils/request'

export const ai_create_session = (data) => {
  return api({ url: '/admin/ai/chat/createSession', method: 'post', data })
}

export const ai_create_chat = (data) => {
  return api({ url: '/admin/ai/chat/createChat', method: 'post', data })
}

export const ai_chat_list = (data) => {
  return api({url:'/admin/ai/chat/sessionDetail', method: 'post', data})
}

export const ai_save_usage = (data) => {
  return api({url:'/admin/ai/chat/saveUsage', method: 'post', data})
}
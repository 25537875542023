<template>
  <el-scrollbar class="ai-chat">
    <div class="ai-chat__inner flex-col flex-1" id="ai">
      <div class="header flex-between">
        <div class="header-left">
          <el-button @click="oldConversations" type="primary"
            >历史对话</el-button
          >
          <el-button @click="newConversation" type="primary">新对话</el-button>
        </div>
        <div class="header-right">
          <el-button plain title="TODO: 点数">点数：2333</el-button>
        </div>
      </div>

      <div class="row">
        <div class="suggestions">
          <div class="intro">{{ '已为您找到询问模板...' }}</div>
          <div class="card">
            <p class="p">{{ '提示词推荐：' }}</p>
            <div class="list flex flex-col">
              <div
                :key="s"
                @click="question += s"
                class="sentence cursor-pointer"
                v-for="s in suggestions"
              >
                <p class="p">{{ s }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 对话上下文 -->
      <div
        :key="JSON.stringify(d)"
        class="row"
        style="padding-top: 12px; padding-bottom: 0"
        v-for="d in dialogs"
      >
        <!--        {{ d.role }}: {{ d.content }}-->
        <div class="dialog-item" v-if="d.role === 'user'">
          <a-i-chat-item :me="d.role === 'user'" v-bind="d" />
        </div>
        <div class="dialog-item" v-else-if="d.role === 'assistant'">
          <a-i-chat-item v-bind="d" />
        </div>
        <!-- 考虑还有其他，如：系统提示，仅前端使用，不要提交给后端 -->
        <div class="dialog-item" v-else-if="d.role === 'system'">
          <a-i-chat-item v-bind="d" />
        </div>
      </div>

      <div class="row" style="min-height: 80px" v-loading="waiting"></div>

      <!-- 自适应 -->
      <div class="flex-1"></div>

      <div class="footer flex-col">
        <!-- 自适应 -->
        <div class="flex-1"></div>
        <!-- 快捷功能 -->
        <el-scrollbar
          style="width: 100%; height: 66px; overflow-y: hidden"
          v-if="false"
        >
          <div class="shortcut-list flex">
            <div
              :key="s.name"
              class="shortcut-item cursor-pointer flex-align-center"
              v-for="s in shortcuts"
            >
              <!--              <img class="icon" />-->
              <span class="span">{{ s.name }}</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </el-scrollbar>
        <!-- 输入区域 -->
        <div class="input-box flex">
          <div :class="{ less: lessThan }" class="input-wrapper flex-1">
            <div
              class="input-wrapper-inner"
              style="width: 100%; overflow-x: hidden"
            >
              <el-input
                :autosize="{ minRows: 1, maxRows: 24 }"
                @focus="onResize"
                placeholder="有问题尽管问我..."
                type="textarea"
                v-model="question"
              />
              <div
                class="el-textarea__inner"
                ref="questionRef"
                style="white-space: pre-wrap; position: fixed; left: 200%"
              >
                {{ question }}
              </div>
            </div>
          </div>

          <div @click="sendQuestion" class="send flex-center cursor-pointer">
            <i class="el-icon-top"></i>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import {
  ai_chat_list,
  ai_create_chat,
  ai_create_session,
  ai_save_usage,
} from '../api/ai-chat'
import fetchStream from '../../../base/utils/fetchStream'
import dayjs from 'dayjs'
import AIChatItem from './AIChatItem'
// 启用中文
require('dayjs/locale/zh-cn')
dayjs.locale('zh-cn')

// 相对时间
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)
const relativeTimeConfig = {
  future: '%s内',
  past: '%s前',
  s: '刚刚',
  m: '1 分钟',
  mm: '%d 分钟',
  h: '1 小时',
  hh: '%d 小时',
  d: '1 天',
  dd: '%d 天',
  M: '1 个月',
  MM: '%d 个月',
  y: '1 年',
  yy: '%d 年',
}
dayjs.updateLocale('zh-cn', {
  relativeTime: relativeTimeConfig,
})

export default {
  name: 'ai-chat',
  components: { AIChatItem },
  data() {
    const now = new Date().getTime()
    console.log(dayjs(now).fromNow(true))

    const data = [
      // {
      //   time: dayjs(now).fromNow(true),
      //   content: '帮我写一份发言稿。',
      //   role: 'user',
      // },
      // {
      //   time: dayjs(now).fromNow(true),
      //   content:
      //     '尊敬的各位来宾、女士们、先生们：\n' +
      //     '大家好！\n' +
      //     '首先，我要感谢主办方给我这个机会，在这里与大家分享[发言主题]。今天，我们将一起探讨[具体话题]，这个话题对我们每个人都至关重要，因为它直接影响着我们的[相关领域或生活方面]。\n' +
      //     '在过去的[时间段]里，我们见证了[相关领域的变化或发展]。这些变化不仅改变了我们的[生活方式/工作方式/思考方式]，而且还带来了一系列新的挑战和机遇。在这个过程中，我们学到了[一个或几个重要的观点或经验]。',
      //   role: 'assistant',
      // },
    ]

    return {
      suggestions: [
        '帮我策划一个主题为《东莞茂商会课程之商学院六月课程 - 低成本运作品牌》的活动。',
        '帮我写一份活动简介',
      ],
      question: '你好',
      shortcuts: [
        {
          name: '文章编辑',
        },
        {
          name: '活动策划',
        },
        {
          name: '日常运营',
        },
      ],
      lessThan: true, // 提问少于 n 行时

      dialogs: [...data],

      waiting: false,
    }
  },
  mounted() {
    this.getChatList()
  },
  methods: {
    oldConversations() {
      this.$message.info('TODO: 历史对话')
    },
    newConversation() {
      this.createSession()
    },
    getDialogs() {
      return [
        ...this.dialogs,
        {
          role: 'user', // only assistant or user
          content: this.question,
        },
      ]
    },
    getLastDialog() {
      return this.dialogs[this.dialogs.length - 1]
    },
    async sendQuestion() {
      this.waiting = true

      try {
        if (!this.session_id) {
          // this.$message.success('对话初始化未完成，请稍后再试')
          // return
          await this.createSession()
        }
        const { data } = await ai_create_chat({
          // {
          //   "session_id":"",//会话id
          //     "role_type":0,//0：用户提问，1：ai回答，2：模板背景
          //     "content":"",
          //     "is_same_problem":0//用户是否将问题修改后再提问
          // }
          session_id: this.session_id,
          role_type: 0,
          content: this.question,
          is_same_problem: 0,
        })
        if (data) {
          this.chat_id = data.chat_id

          // UI更新
          this.dialogs.push({
            role: 'user',
            content: this.question,
            time: dayjs(Date.now()).fromNow(true),
          })

          // 询问 AI
          fetchStream({
            url: '/admin/ai/chat/send',
            method: 'post',
            data: {
              session_id: this.session_id,
            },
            // 读取流，TODO：名字改成 onmessage 好点？结构和后端的一致：{code,data,msg}
            callback: async (responseData) => {
              this.waiting = false // 等到回复了
              const { code, data, msg } = responseData
              if (code === 1) {
                const { answer, is_end } = data
                if (this.getLastDialog().role === 'assistant') {
                  // 追加回答内容
                  this.getLastDialog().content += answer // 打字机效果没有

                  if (is_end) {
                    console.log('回答完毕')
                    // TODO：记录tokens消耗

                    const { completion_tokens, prompt_tokens, total_tokens } =
                      data.usage
                    this.saveUsage(
                      prompt_tokens,
                      completion_tokens,
                      total_tokens
                    )
                    await ai_create_chat({
                      // {
                      //   "session_id":"",//会话id
                      //     "role_type":0,//0：用户提问，1：ai回答，2：模板背景
                      //     "content":"",
                      //     "is_same_problem":0//用户是否将问题修改后再提问
                      // }
                      session_id: this.session_id,
                      role_type: 1,
                      content: this.getLastDialog().content, // AI回答的完整内容
                      is_same_problem: 0,
                    })
                    this.getChatList()
                  }
                } else {
                  // AI 开始回答了
                  this.dialogs.push({
                    role: 'assistant',
                    content: answer,
                  })
                }
              } else {
                // TODO: 出错了
                this.waiting = false
              }
            },
          }).catch((err) => {
            console.log(err, 'fetchStream err::')
            this.waiting = false
            this.dialogs.push({
              role: 'system',
              content: err.message || JSON.stringify(err),
              time: dayjs(new Date().getTime()).fromNow(true),
            })
          })
        } // 先保存起来
      } catch (e) {
        console.log(e)
        this.waiting = false
      }
    },
    saveUsage(a, b, c) {
      ai_save_usage({
        /**
         * {
            "session_id": "x5EgrGMN",
            "prompt_tokens":1,
            "completion_tokens":1,
            "total_tokens":2
        }*/
        session_id: this.session_id,
        prompt_tokens: a,
        completion_tokens: b,
        total_tokens: c,
      }).catch(() => {})
    },
    createSession() {
      return ai_create_session()
        .then(({ data, msg }) => {
          this.session_id = data.session_id
          this.$message.success('新建对话开始')
        })
        .catch(() => {})
    },
    /**
     * 超过指定行数，textarea 产生自带的滚动条并不好看
     * 这边监听超出后隐藏（即往右侧偏移一点）
     */
    onResize() {
      const resizeObserver = new ResizeObserver((entries) => {
        //回调
        console.log(this.$refs.questionRef.offsetHeight)
        // 96 就是 四行文字的高度，字号 16，行高 1.5
        if (this.$refs.questionRef.offsetHeight < 96) {
          this.lessThan = true
        } else {
          this.lessThan = false
        }
      })

      //监听对应的dom
      resizeObserver.observe(this.$refs.questionRef)
    },
    getChatList() {
      if (!this.session_id) return
      ai_chat_list({
        session_id: this.session_id,
      })
        .then(({ data, msg }) => {
          this.dialogs = []
          this.dialogs.push(
            data.chat_list
              .filter((ch) => ch.data_type === 1)
              .map((el) => el.data)
          )
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-scrollbar__wrap {
  display: flex;
  flex-direction: column;

  .el-scrollbar__view {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.header {
  width: 760px;
  height: 76px;
  flex-shrink: 0;
  border: 0.5px solid #00c2ff26;
  background: linear-gradient(0deg, #00c2ff0d 0%, #00c2ff0d 100%), #fff;
  padding-right: 60px;
  padding-left: 26px;
  top: 0;

  .el-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-shrink: 0;
    border-radius: 6px;

    &:hover {
      box-shadow: inset 0 0 10px 2px #00b2ff1a;
    }

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 27.2px;
    }
  }

  .el-button--primary {
    height: 36px;
    background: #00b2ff;
    border: none;

    span {
      color: #ffffff;
    }
  }

  .el-button--default.is-plain {
    height: 40px;
    border: 1px solid #00b2ff33;
    background: #00b2ff0f;

    color: #00b2ff;

    span {
      color: #00b2ff;
      text-align: right;
    }
  }
}

.row {
  padding: $space;
}

.suggestions {
  .intro {
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background: linear-gradient(
      116deg,
      #d04bff 16.34%,
      #fe7ed2 48.79%,
      #ffb4fc 83.66%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    width: 158px;
    height: 24px;

    & + .card {
      margin-top: 11px;
    }
  }

  .card {
    display: inline-flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid #6fdfff33;
    background: linear-gradient(118deg, #f6fdffcc 0%, #f5fdffcc 100%);
    max-width: 604px;

    .p {
      color: #008ab6;
      font-variant-numeric: lining-nums tabular-nums;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }

    .list {
      align-items: flex-start;
    }

    .sentence {
      display: flex;
      padding: 10px;
      align-items: flex-start;
      gap: 6px;
      border-radius: 4px;
      border: 0.5px solid #00a2d633;
      background: #effbff;

      &:hover {
        box-shadow: inset 0 0 10px 2px #00a2d633;
      }

      & + .sentence {
        margin-top: 12px;
      }

      .p {
        color: #008ab6;
        font-variant-numeric: lining-nums tabular-nums;
        font-family: 'Noto Sans SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }
}

.footer {
  min-height: 142px;
  bottom: 10px;
  background-color: white;

  ::v-deep .el-scrollbar__wrap {
    /*overflow: auto hidden;*/
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 2px;
  }

  ::v-deep .el-scrollbar__bar.is-vertical {
    /*display: none;*/
    opacity: 0;
  }

  .input-box {
    width: 740px;
    min-height: 66px;
    flex-shrink: 0;
    border-radius: 8px;
    background: white;
    box-shadow: 0 2px 4px 0 #dae0e714, 0 4px 24px 0 #bfc7d03d;
    margin-left: 10px;
    margin-top: 8px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 4px;
      height: 66px;
      flex-shrink: 0;
      //background: url(<path-to-image>) lightgray -66.91px -3.57px / 2654.488% 114.849% no-repeat;
    }

    .el-textarea {
      $offset: 15px;

      width: calc(100% + #{$offset});
      margin-right: -#{$offset};
    }

    ::v-deep .el-textarea__inner {
      border: none;
      padding: 0;
      font-size: 16px;
      line-height: 1.5;
    }

    .input-wrapper {
      padding: 21px 12px 10px;

      &.less {
        .el-textarea {
          width: 100%;
          margin-right: unset;
        }
      }

      .input-wrapper-inner {
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: white;
          width: 0.5em;
          height: 0.5em;
        }
      }
    }
  }

  .shortcut-list {
    flex-wrap: nowrap;
    padding: 10px 20px 0;

    .shortcut-item {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;

      color: #4e184b;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;

      /*&:hover {*/
      /*  transform: scale(1.05);*/
      /*}*/

      &:first-child {
        border: 1px solid #ffe8fd;
        background: #fff7ff;

        &:hover {
          box-shadow: inset 0 0 10px 2px #ffe8fd;
        }
      }

      &:nth-child(2) {
        border: 1px solid #ffece8;
        background: #fffaf8;

        &:hover {
          box-shadow: inset 0 0 10px 2px #ffece8;
        }
      }

      &:nth-child(3) {
        border: 1px solid #cdffdb;
        background: #f5fffa;

        &:hover {
          box-shadow: inset 0 0 10px 2px #cdffdb;
        }
      }

      & + .shortcut-item {
        margin-left: 12px;
      }

      .icon {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }

      .el-icon-arrow-right {
        /*margin-left: 4px;*/
        font-size: inherit;
      }
    }
  }

  .send {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 27px;
    //background: url(<path-to-image>) lightgray -64px -32.46px / 500% 302.863% no-repeat, #D9D9D9;
    position: absolute;
    right: 17px;
    bottom: 17px;
    background-color: blue;

    .el-icon-top {
      color: white;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.header,
.footer {
  position: sticky;
}
</style>

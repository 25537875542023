<template>
  <div :class="{ me }" class="ai-chat-item flex">
    <div class="main flex-col">
      <time :datatime="time">{{ time }}</time>
      <div class="block">
        <p class="content">{{ content }}</p>
      </div>
    </div>
    <el-avatar
      :size="32"
      :src="fixImageUrl(headerData.admin_user.avatar, 300)"
      class="avatar"
    ></el-avatar>
  </div>
</template>

<script>
export default {
  props: {
    me: {
      type: Boolean,
      default: false,
    },
    content: String,
    time: String,
  },
  data() {
    return {}
  },
  computed: {
    // header数据
    headerData() {
      return this.$store.getters['header/headerData']
    },
  },
  methods: {},
}
</script>

<style lang="scss">
.ai-chat-item {
  flex-direction: row-reverse;
  justify-content: flex-end;

  &.me {
    flex-direction: row;
    /*justify-content: flex-end;*/

    .main {
      align-items: flex-end;
    }

    .block {
      background: #0085ff;

      .content {
        color: #ffffff;
      }
    }
  }

  .main {}

  .block {
    display: inline-flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    max-width: 500px;
    background-color: #F8F9FB;

    .content {
      font-size: 15px;
      /*font-weight: 500;*/
      line-height: 25.5px;
      color: #1A1A1A;
      text-align: justify;
    }
  }

  time {
    color: #b3b3b3;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .main {
    margin: 0 7px;
  }

  .avatar {
    flex-shrink: 0;
  }
}
</style>

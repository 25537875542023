import RequestError from './request-error'
import store from '@/base/store'
import { randomString, timestamp } from './tool'
import { getType } from './tool'
import { Message } from 'element-ui'

/**
 * 参考同目录下的 download.js 改的 EventStream 读取工具
 */
class EventStreamTool {
  baseUrl = '' //请求地址前缀
  //自定义配置
  options = {
    url: '', //链接地址
    cache: 'no-cache',
    method: 'POST',
    mode: 'cors',
    callback: () => {},
  }
  //头信息
  headers = new Headers()
  //上次的数据
  body = {}

  constructor(options = {}) {
    this.initConfig(options) //初始化
  }

  //初始化请求配置
  initConfig(options) {
    if (!options.baseUrl && !options.url)
      throw new Error('url参数不存在或者错误！')

    //合并基础配置
    Object.keys(this.options).forEach((key) => {
      if (key === 'method') {
        this.options[key] = options[key]
          ? options[key].toUpperCase()
          : this.options[key]
      } else {
        this.options[key] = options[key] ?? this.options[key]
      }
    })

    //url和头信息
    if (process.env.NODE_ENV === 'development') {
      this.baseUrl = options.baseUrl ?? '/api' //使用反代
    } else {
      this.baseUrl = options.baseUrl ?? window.serverConfig.VUE_APP_BASEURL //全局js变量
    }

    //header
    // 用于短信验证码
    const n = timestamp()
    const l = store.getters.localTimestamp
    const ser = store.getters.serverTimestamp
    this.headers.append('timestamp', n - l + ser)
    this.headers.append('nonce', randomString(16))

    //token头
    if (window.serverConfig.VUE_APP_HEADER_TOKEN) {
      const key = window.serverConfig.VUE_APP_HEADER_TOKEN
      const token = store.getters['user/token']
      this.headers.append(key, token ?? '') //手机验证码需要
    }
    //自定义头
    if (getType(options.headers) === 'object') {
      Object.keys(options.headers).forEach((key) => {
        this.headers.append(key, options.headers[key])
      })
    }

    //其他头信息
    if (window.serverConfig.VUE_APP_OTHER_HEADERS) {
      const otherHeaders = window.serverConfig.VUE_APP_OTHER_HEADERS
      for (let key in otherHeaders) {
        this.headers.append(key, otherHeaders[key])
      }
    }

    //data数据
    if (options.data) {
      if (getType(options.data) === 'object') {
        //数据初始化
        switch (this.options.method) {
          case 'GET':
            this.initGetData(options.data)
            break
          case 'POST':
            this.initPostData(options.data)
            break
          default:
            throw new Error(`${this.options.method}该协议暂未支持！`)
        }
      }
    } else {
      throw new Error('data参数不正确，他是一个键值对对象！')
    }
  }

  //初始化GET数据 data:{}
  initGetData(data) {
    let url = this.options.url
    if (url.indexOf('?') === -1) {
      url += '?'
    } else if (url.endsWith('?') && !url.endsWith('&')) {
      url += '&'
    }
    Object.keys(data).forEach((key) => {
      url += `${key}=${data[key]}`
    })

    this.options.url = url
  }

  //初始化POST数据
  initPostData(data) {
    const dataType = getType(data)
    if (dataType === 'formdata') {
      this.body = data
    } else {
      let form = new FormData()
      Object.keys(data).forEach((key) => {
        if (getType(data[key]) === 'array') {
          form.append(key, JSON.stringify(data[key]))
        } else {
          form.append(key, data[key])
        }
      })

      this.body = form
    }
  }

  //发起请求
  initiateRequest() {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + this.options.url
      const postConfig = Object.assign(
        {},
        this.options,
        { headers: this.headers },
        { body: this.body }
      )
      fetch(url, postConfig)
        // 根据状态码过滤掉请求失败的情况
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response
          }
          const error = new Error(response.statusText)
          error.response = response
          throw error
        })
        .then(async (response) => {
          try {
            // if (!response.body) {
            //   return
            // }

            const reader = response.body.getReader()

            while (true) {
              const { value, done } = await reader.read()
              if (done) {
                console.log(`done.`) // 错误 500 也会走这里
                break // 读取完毕
              } else {
                // 将分块数据转换为 string 交给外部处理函数使用
                const dataText = new TextDecoder().decode(value)
                const textArr = dataText.split('\n\n').filter(Boolean) // response 响应的消息可能存在多个，以 \n\n 分割

                // 先处理成正常数据
                let objArr = []
                for (let n = 0; n < textArr.length; n++) {
                  try {
                    const textNow = textArr[n]

                    const parseBefore = textNow.replace(`data:`, '').trim()
                    if (parseBefore.startsWith(`<!DOCTYPE html>`)) {
                      throw new Error('服务器错误') // 错误源头
                      break
                    }

                    // 撇除开头的 data: ，这是流式响应约定好的写法
                    const responseData = JSON.parse(parseBefore)
                    console.log('读取数据：', responseData) // 后端返回的固定结构：{code, msg, data}

                    // TODO：后端报错，终止回答
                    if (responseData.code === 0) {
                      break
                    }

                    // const demo = {
                    //   answer: '您好，我是科大讯飞研发的认知智能大模型',
                    //   usage: {
                    //     question_tokens: 2,
                    //     prompt_tokens: 3,
                    //     completion_tokens: 40,
                    //     total_tokens: 43
                    //   }
                    // }
                    objArr.push(responseData) // {answer, is_end, usage}
                  } catch (e) {
                    console.log(e)

                    if (n === 0) {
                      // 第一个流就报错了
                    } else {
                      // JSON parse 出错了
                    }

                    // TODO: 可能是服务器错误
                    // Message.error('出错了')
                    throw new Error(e.message || JSON.stringify(e)) // 抛给 download.js error

                    break
                  }
                }

                for (let n = 0; n < objArr.length; n++) {
                  // 兼容响应体结构
                  const { answer, result, is_end, usage, seq } = objArr[n]

                  this.options.callback(objArr[n])

                  // if (usage) {
                  //   // TODO：更新点数
                  // }
                }
              }
            }

            // 作者：明里人
            // 链接：https://juejin.cn/post/7249286903207641146
            //     来源：稀土掘金
            // 著作权归作者所有。商业转载请联系作者获得授权，非商业转载请注明出处。
          } catch (error) {
            console.log(error, 'download.js error') // 抛给了我
            RequestError.error(error.message)
            return reject(error)
          }
        })
        .then((response) => {})
        .catch((error) => {
          return reject(error)
        })
    })
  }
}

//触发器
function fetchStream(options) {
  return new EventStreamTool(options).initiateRequest()
}

export default fetchStream
